"use client";
import { usePathname, useSearchParams } from "next/navigation";
import { useState } from "react";

import { shareContentEvent } from "@/analytics/event-logger";
import { MediaQuery } from "@/contexts/media-context";
import { type CalendarEvent, createICalLink, getGoogleCalendarLink } from "@/utils/calendar-utils";
import { Logger } from "@/utils/logger";
import { ROOT_URL } from "@/utils/paths-utils";
import { classNames } from "@/utils/styling-utils";
import { CalendarDaysIcon, CopyCheckIcon, CopyIcon, GoogleCalendarIcon, ShareIcon } from "../icons/icons";
import { IconButton } from "./icon-button";
import { IconLinkButton } from "./icon-link-button";

interface ShareButtonsProps {
  buttonType?: "primary";
  calendarEvent?: CalendarEvent;
  shareTitle?: string;

  /**
   * Set shareUrl if you want to share a different URL than the current page URL
   */
  shareUrl?: string;
  showCalendarOnly?: boolean;
}

export const ShareButtons = ({
  buttonType,
  calendarEvent,
  shareTitle,
  shareUrl,
  showCalendarOnly = false,
}: ShareButtonsProps) => {
  const icalLink = calendarEvent ? createICalLink(calendarEvent) : "";
  const googleLink = calendarEvent ? getGoogleCalendarLink(calendarEvent) : "";

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const url = shareUrl ?? `${ROOT_URL}${pathname}${searchParams ? `?${searchParams}` : ""}`;

  const dataToShare = {
    text: `Blir du med${shareTitle ? ` på ${shareTitle}` : ""}?`,
    title: `Bli med${shareTitle ? ` på ${shareTitle}` : ""}`,
    url: url,
  };

  return (
    <div className={classNames("share-buttons", buttonType === "primary" && "share-buttons--primary")}>
      {/* Viser 'del'-ikon kun på mobil */}
      {typeof window !== "undefined" && navigator.share && navigator.canShare(dataToShare) && !showCalendarOnly && (
        <MediaQuery lessThan="lg">
          <IconButton
            ariaLabel="Del aktiviteten med venner"
            buttonType={buttonType}
            onClick={async () => {
              try {
                await navigator.share(dataToShare);
                shareContentEvent(shareTitle, shareUrl);
              } catch {
                // console.log("Could not share because ", error); // TEMP: logg feil et eller annet sted, bare ikke til console
              }
            }}
          >
            <ShareIcon />
          </IconButton>
        </MediaQuery>
      )}

      {/* Viser 'kopier'-ikon kun på desktop */}
      {typeof window !== "undefined" && navigator.clipboard && !showCalendarOnly && (
        <MediaQuery greaterThanOrEqual="lg">
          <IconButton
            ariaLabel="Kopier link"
            buttonType={buttonType}
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(url).then(() => {
                  setIsCopied(true);
                });
                shareContentEvent(shareTitle, shareUrl);
              } catch (error) {
                Logger.error(error);
              }
            }}
          >
            {isCopied ? <CopyCheckIcon /> : <CopyIcon />}
          </IconButton>
        </MediaQuery>
      )}

      {icalLink && (
        <IconLinkButton ariaLabel="Legg til i kalender" buttonType={buttonType} href={icalLink} download="event.ics">
          {buttonType === "primary" ? <CalendarDaysIcon color="white" /> : <CalendarDaysIcon />}
        </IconLinkButton>
      )}

      {googleLink && (
        <IconLinkButton
          ariaLabel="Legg til i Google-kalender"
          buttonType={buttonType}
          href={googleLink}
          openUrlInNewTab={true}
        >
          <GoogleCalendarIcon />
        </IconLinkButton>
      )}
    </div>
  );
};
