import {
  BookingHeaderRowFilterTypes,
  type SelectedResource,
  bookingTypeToServiceId,
  serviceIdToBookingType,
} from "@/components/booking/types";
import {
  type ArticleItemFragment,
  type BookingButtonFragment,
  type EventItemFragment,
  type FilterInput,
  FilterTypes,
  type GroupActivityItemFragment,
  type MyBookedEventFragment,
  type MyBookedServicesFragment,
  type MyGroupActivityBookingFragment,
  type MyNoShowBookedServicesFragment,
  type MyNoShowBookingFragment,
  type MyWaitinglistBookingFragment,
  type OrderItem,
  OrderItemTypes,
  type Period,
  type Reference,
  type ServiceItemFragment,
  type SubscriptionItemFragment,
  type ValueCardItemFragment,
} from "@/generated/client.generated";
import dayjs, { type Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { equals, groupBy, intersection, union } from "rambda";

dayjs.extend(isBetween);
export type BookingTypes =
  | MyWaitinglistBookingFragment
  | MyGroupActivityBookingFragment
  | MyBookedServicesFragment
  | MyNoShowBookedServicesFragment
  | MyNoShowBookingFragment
  | MyBookedEventFragment;

export type ServicesBookingTypes = MyBookedServicesFragment | MyNoShowBookedServicesFragment;

export type MyGroupActivityBookingTypes =
  | MyWaitinglistBookingFragment
  | MyGroupActivityBookingFragment
  | MyNoShowBookingFragment;

export const splitBookingTypes = (b: BookingTypes) => {
  const serviceBookings: Array<ServicesBookingTypes> = [];
  const groupActivityBookings: Array<MyGroupActivityBookingTypes> = [];
  const eventBookings: Array<MyBookedEventFragment> = [];

  if (b.__typename === "ServiceBooking" || b.__typename === "NoShowServiceBooking") {
    serviceBookings.push(b as ServicesBookingTypes);
  }
  if (b.__typename === "BookedEvent") {
    eventBookings.push(b);
  }
  if (
    b.__typename === "GroupActivityBooking" ||
    b.__typename === "NoShowBooking" ||
    b.__typename === "WaitingListBooking"
  ) {
    groupActivityBookings.push(b as MyGroupActivityBookingTypes);
  }
  return { eventBookings, groupActivityBookings, serviceBookings };
};
// updates currentFilterCache based on supplied modified filters
export const handleSelectedMultipleFilters = (
  selected: Array<FilterInput>,
  currentFiltersCache: Array<FilterInput>,
  activeFilterTypes: Array<BookingHeaderRowFilterTypes>,
) => {
  // handle new date filters
  let newDateFilters: Array<FilterInput> = [];
  if (activeFilterTypes.find((filterType) => filterType === BookingHeaderRowFilterTypes.WEEKDAYSROW)) {
    newDateFilters = handleDateFilters(selected, currentFiltersCache);
  }

  // only non date filters
  const newNonDateFilters = selected.filter((f) => f.type !== FilterTypes.ToDate && f.type !== FilterTypes.FromDate);

  // the current non date filters
  const oldNonTimeFilters = currentFiltersCache.filter(
    (f) => f.type !== FilterTypes.ToDate && f.type !== FilterTypes.FromDate,
  );

  // as the filters use a toggle we remove duplicates between the old and the new using a union operation
  const unionFilters = union(newNonDateFilters, oldNonTimeFilters);
  const intersectOfFilters = intersection(newNonDateFilters, oldNonTimeFilters);
  const correctNonDateFilters = unionFilters.filter((f) => intersectOfFilters.findIndex((f2) => equals(f, f2)) === -1);

  const newFilters = [...correctNonDateFilters, ...newDateFilters];
  return newFilters;
};

export function sortBookings(bookings: readonly BookingTypes[]): BookingTypes[] {
  const booked = [...bookings].sort((a: BookingTypes, b: BookingTypes) => {
    let startA: Dayjs | null = null;
    let startB: Dayjs | null = null;

    if (a.__typename === "ServiceBooking" || a.__typename === "NoShowServiceBooking") {
      startA = dayjs(a.start);
    } else if (a.__typename === "BookedEvent") {
      startA = dayjs(a.bookableEvent?.duration.start);
    } else {
      startA = dayjs(a.BookableGroupActivity.start);
    }

    if (b.__typename === "ServiceBooking" || b.__typename === "NoShowServiceBooking") {
      startB = dayjs(b.start);
    } else if (b.__typename === "BookedEvent") {
      startB = dayjs(b.bookableEvent?.duration.start);
    } else {
      startB = dayjs(b.BookableGroupActivity.start);
    }
    return startA.isBefore(startB) ? -1 : 1;
  });

  return booked;
}
// get date filters if they exist in selected filters, otherwise keep current, otherwise fall back to today
export const handleDateFilters = (selected: Array<FilterInput>, currentFiltersCache: Array<FilterInput>) => {
  return [
    selected.find((f) => f.type === FilterTypes.FromDate) ??
      currentFiltersCache.find((f) => f.type === FilterTypes.FromDate) ?? {
        type: FilterTypes.FromDate,
        value: dayjs().format("YYYY-MM-DD"),
      },
    selected.find((f) => f.type === FilterTypes.ToDate) ??
      currentFiltersCache.find((f) => f.type === FilterTypes.ToDate) ?? {
        type: FilterTypes.ToDate,
        value: dayjs().add(1, "day").format("YYYY-MM-DD"),
      },
  ];
};

export const selectedResourceToOrderItem = (selectedResource: SelectedResource) => {
  return {
    amount: null,
    birthDate: undefined,
    code: null,
    //service type id
    productId: bookingTypeToServiceId(selectedResource.bookingType),
    receiverDetails: null,
    //Id of court
    resourceId: selectedResource.court.laneId.toString(),
    senderDetails: null,
    start: selectedResource.startTime,
    type: OrderItemTypes.Service,
  };
};

export const orderItemTypeToTitle = (orderItemType: OrderItemTypes, reference: Reference | null) => {
  switch (orderItemType) {
    case OrderItemTypes.Service:
      return `${serviceIdToBookingType(reference?.id ? +reference.id : undefined)?.toLocaleLowerCase()}`;
    case OrderItemTypes.Valuecard: {
      if (reference?.name.toLocaleLowerCase().includes("paykort")) {
        return "Fyll på paykort";
      }
      if (reference?.name.toLocaleLowerCase().includes("gavekort")) {
        return "Kjøp gavekort";
      }
      return "Kjøp klippekort";
    }
    case OrderItemTypes.Article:
      return "Sett opp hvilemedlemskap";
    case OrderItemTypes.Event:
      return "Booking";
    default:
      return orderItemType.toLocaleLowerCase();
  }
};

export function orderItemToInputOrderItem(
  item:
    | ServiceItemFragment
    | GroupActivityItemFragment
    | ValueCardItemFragment
    | ArticleItemFragment
    | SubscriptionItemFragment
    | EventItemFragment,
): OrderItem {
  switch (item.type) {
    case OrderItemTypes.Article: {
      const articleItem = item as ArticleItemFragment;
      return {
        amount: null,
        birthDate: undefined,
        code: null,
        productId: articleItem.product.id,
        receiverDetails: null,
        resourceId: articleItem.id,
        senderDetails: null,
        start: undefined,
        type: OrderItemTypes.Article,
      };
    }
    case OrderItemTypes.Groupactivity: {
      const groupActivityItem = item as GroupActivityItemFragment;
      return {
        amount: null,
        birthDate: undefined,
        code: null,
        productId: groupActivityItem.product.id,
        receiverDetails: null,
        resourceId: groupActivityItem.id,
        senderDetails: null,
        start: groupActivityItem.duration.start,
        type: OrderItemTypes.Groupactivity,
      };
    }
    case OrderItemTypes.Service: {
      const serviceItem = item as ServiceItemFragment;
      return {
        amount: null,
        birthDate: undefined,
        code: null,
        productId: serviceItem.product.id,
        receiverDetails: null,
        resourceId: serviceItem?.selectedResource?.laneId.toString() ?? "",
        senderDetails: null,
        start: serviceItem.duration.start,
        type: OrderItemTypes.Service,
      };
    }
    case OrderItemTypes.Subscription: {
      const subscriptionItem = item as SubscriptionItemFragment;
      return {
        amount: null,
        birthDate: undefined,
        code: null,
        productId: subscriptionItem.product.id,
        receiverDetails: null,
        resourceId: subscriptionItem.id,
        senderDetails: null,
        start: undefined,
        type: OrderItemTypes.Subscription,
      };
    }
    case OrderItemTypes.Valuecard: {
      const valueCardItem = item as ValueCardItemFragment;
      return {
        amount: null,
        birthDate: undefined,
        code: null,
        productId: valueCardItem.product.id,
        receiverDetails: null,
        resourceId: valueCardItem.id,
        senderDetails: null,
        start: undefined,
        type: OrderItemTypes.Valuecard,
      };
    }
    default: {
      throw new Error(`ItemType ${item.type} not supported`);
    }
  }
}

export const groupServicesByDate = (
  services: Array<
    | ServiceItemFragment
    | GroupActivityItemFragment
    | ValueCardItemFragment
    | ArticleItemFragment
    | SubscriptionItemFragment
    | EventItemFragment
  >,
) => {
  const groupFn = (
    service:
      | ServiceItemFragment
      | GroupActivityItemFragment
      | ValueCardItemFragment
      | ArticleItemFragment
      | SubscriptionItemFragment
      | EventItemFragment,
  ) => {
    if (service.__typename === "ServiceItem" || service.__typename === "GroupActivityItem") {
      return dayjs(service.duration.start).format("dddd DD. MMMM");
    }
    return "";
  };

  return groupBy(groupFn, services);
};

export const groupMyBookedByDate = (booked: Array<BookingTypes>) => {
  const groupFn = (booking: BookingTypes) => {
    if (
      booking.__typename === "WaitingListBooking" ||
      booking.__typename === "GroupActivityBooking" ||
      booking.__typename === "NoShowBooking"
    ) {
      return dayjs(booking.BookableGroupActivity.start).format("dddd DD. MMMM");
    }
    if (booking.__typename === "BookedEvent") {
      return dayjs(booking.bookableEvent?.duration.start).format("dddd DD. MMMM");
    }
    return dayjs(booking.start).format("dddd DD. MMMM");
  };
  return groupBy(groupFn, booked);
};

export const inBookingPeriod = (dateToCheck: Dayjs, bookingPeriod: Period) => {
  const isInBookingPeriod = dateToCheck.isBetween(bookingPeriod.start, bookingPeriod.end, undefined, "[]"); // start- and endpoint inclusive

  let error: string | undefined;

  if (!isInBookingPeriod) {
    if (dateToCheck.isAfter(bookingPeriod.start)) {
      error = "TOO_LATE_TO_BOOK";
    } else {
      error = "TOO_EARLY_TO_BOOK";
    }
  }
  return { error, isInBookingPeriod };
};

type BookingIsEnabled = {
  isEnabled: boolean;
  code: string;
};

export function isBookable(parts: BookingButtonFragment, cancelled?: boolean): BookingIsEnabled {
  if (cancelled) {
    return {
      code: "CANNOT_BOOK_CANCELLED_ACTIVITY",
      isEnabled: false,
    };
  }

  const now = dayjs();
  const bookableEarliest = parts.bookableEarliest ? dayjs(parts.bookableEarliest) : null;
  const bookableLatest = parts.bookableLatest ? dayjs(parts.bookableLatest) : null;

  const startDate = dayjs(parts.start);
  if (startDate.isBefore(dayjs().add(2, "hours")) && parts.slot.hasWaitingList && parts.slot.available === 0) {
    return {
      code: "TOO_LATE_TO_BOOK_WAITINGLIST",
      isEnabled: false,
    };
  }
  if (bookableLatest?.isBefore(now)) {
    return {
      code: "TOO_LATE_TO_BOOK",
      isEnabled: false,
    };
  }
  if (bookableEarliest?.isAfter(now)) {
    return {
      code: "TOO_EARLY_TO_BOOK",
      isEnabled: false,
    };
  }
  return {
    code: "",
    isEnabled: true,
  };
}
